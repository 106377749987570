<template>
  <div class="page_container">
    <div class="page_head">
      <div class="page_head-title">Didactical Models</div>
    </div>
    <div class="page_content">
      <div class="page_content-container" :class="{'full_page': right_bar}">
        <div class="page_content-title">Models</div>
        <div class="model_container">
          <div class="model_item" v-for="model of my_models">
            <div class="model_item-text description">
              <div class="model_item-value">{{model.name || '...'}}</div>
            </div>
            <div class="model_item-text">
              <div class="model_item-key">Methodological Description</div>
              <div class="model_item-value"><p class="model_item-value_icon"></p>{{model.methodological_description || '...'}}</div>
            </div>
            <div class="model_item-text">
              <div class="model_item-key">Teachers and Students Roles</div>
              <div class="model_item-value"><p class="model_item-value_icon"></p>{{model.teachers_and_students_roles || '...'}}</div>
            </div>
            <div class="model_item-text">
              <div class="model_item-key">Class Settings</div>
              <div class="model_item-value"><p class="model_item-value_icon"></p>{{model.class_settings || '...'}}</div>
            </div>
            <div class="model_item-text">
              <div class="model_item-key">Evaluation Methods</div>
              <div class="model_item-value"><p class="model_item-value_icon"></p>{{model.evaluation_methods || '...'}}</div>
            </div>
            <div class="model_item-text">
              <div class="model_item-key">Needed Tools</div>
              <div class="model_item-value"><p class="model_item-value_icon"></p>{{model.tools_needed || '...'}}</div>
            </div>
            <div class="model_item-text">
              <div class="model_item-key">Criticality and Possible Solutions</div>
              <div class="model_item-value"><p class="model_item-value_icon"></p>{{model.criticality_and_possible_solutions || '...'}}</div>
            </div>
            <div class="model_item-text">
              <div class="model_item-key">Extra Content</div>
              <div class="model_item-value"><p class="model_item-value_icon"></p>{{model.extra_content || '...'}}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="page_right-bar" :class="{'open_bar': right_bar}">
        <div class="bar_btn" @click="openBar()">
          <svg v-if="right_bar" width="24" height="111" viewBox="0 0 24 111" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.3998 110H5.7998C3.2998 110 1.2998 108 1.2998 105.5V5.80005C1.2998 3.30005 3.2998 1.30005 5.7998 1.30005H18.3998C20.8998 1.30005 22.8998 3.30005 22.8998 5.80005V105.5C22.8998 108 20.8998 110 18.3998 110Z"
                stroke="#97D5C9" stroke-width="0.8109" stroke-miterlimit="10"/>
            <path d="M16.9 71.7001L6.5 55.8L17 39.8" stroke="#97D5C9" stroke-width="0.4417"
                  stroke-miterlimit="10"/>
          </svg>
          <svg v-else width="22" height="110" viewBox="0 0 22 110" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.0002 0.5H16.4002C18.8002 0.5 20.7002 2.39999 20.7002 4.79999V104.8C20.7002 107.2 18.8002 109.1 16.4002 109.1H5.0002C2.6002 109.1 0.700195 107.2 0.700195 104.8V4.79999C0.700195 2.49999 2.6002 0.5 5.0002 0.5Z"
                stroke="#97D5C9" stroke-width="0.7835" stroke-miterlimit="10"/>
            <path d="M6.2002 38.9L16.0002 54.8L6.2002 70.8" stroke="#97D5C9" stroke-width="0.4267"
                  stroke-miterlimit="10"/>
          </svg>
        </div>
        <div class="didactic_content" :class="{'hide_content': right_bar}">
          <div class="didactic_title">DIDACTICAL MODEL AND CONTENTS</div>
          <div class="didactic_search">
            <label>Search on:</label>
            <b-form-group class="search_radios">
              <b-form-radio-group
                  class="radio_group"
                  id="radio-group-1"
                  v-model="selected_search"
                  :options="options"
                  name="radio-inline"
              ></b-form-radio-group>
            </b-form-group>
          </div>
          <div class="search_input-container">
            <b-form-input
                v-model="search_data"
            >
            </b-form-input>
            <div class="search_icon"></div>
          </div>
          <div class="educative_title">Didactical Models</div>
          <div class="models_container">
            <div class="model_item"
                 v-for="model in didactical_models"
                 >
                            {{ model.name }}
                  <div class="add_layer" >
                    <button class="add_btn" :class="getMyModel(model.id)" @click="addModel(model)">Add</button>
                    <button class="show_btn" @click="methodModal(model)">Show</button>
                  </div>
            </div>
          </div>

        </div>
      </div>

      <!--Method modal-->
      <b-modal ref="method-modal" centered size="lg" hide-header hide-footer>
        <div class="modal_content" v-if="selectedMethod">
          <div class="modal_title">
            {{ selectedMethod.name }}
          </div>
          <div class="modal_input">
            <label>Methodological description</label>
            <div class="method_item">{{ selectedMethod.methodological_description || '...' }}</div>
            <label>Teacher and student’s role</label>
            <div class="method_item">{{ selectedMethod.teachers_and_students_roles || '...' }}</div>
            <label>Class setting</label>
            <div class="method_item">{{ selectedMethod.class_settings || '...' }}</div>
            <label>Evaluation methods</label>
            <div class="method_item">{{ selectedMethod.evaluation_methods || '...' }}</div>
            <label>Tools needed</label>
            <div class="method_item">{{ selectedMethod.tools_needed || '...' }}</div>
            <label>Criticality and possible solutions</label>
            <div class="method_item">{{ selectedMethod.criticality_and_possible_solutions || '...' }}</div>
            <label>Notes:</label>
            <div class="method_item">{{ selectedMethod.extra_content || '...' }}</div>

            <!--            <b-form-input v-model="new_team" placeholder="Team name"></b-form-input>-->
          </div>

          <div class="modal_btn">
            <b-button class="cancel_btn mx-2" @click="methodModal()">Cancel</b-button>
          </div>

        </div>
      </b-modal>
      <!--Method modal-->

    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "DidacticalModels",
  data() {
    return {
      my_models: [],
      didactical_models: [],
      right_bar: true,
      selected_search: 'chatgpt',
      options: [
        {text: 'chatgpt', value: 'chatgpt'},
        {text: 'google', value: 'google'},
      ],
      search_data: '',
      allMethodologies: [],
      selectedMethod: null
    }
  },
  created() {
    this.getDidacticalModels()
    this.myModelsGet()
    // this.getMethodologies()
  },
  methods: {
    ...mapActions(['didacticalModelsGet', 'getMyModels', 'methodologiesGet', 'addDidacticalModel']),
    getDidacticalModels() {
      this.didacticalModelsGet()
      .then((res) => {
        this.didactical_models = res.data
      })
    },
    myModelsGet() {
      this.getMyModels()
        .then(res => {
          this.my_models = res.data
        })
    },
    addModel(model) {
      this.addDidacticalModel(model.id)
      .then((res) => {
        this.myModelsGet()
      })
    },
    getMyModel(id) {
      const isElementPresent = this.my_models.some((o) => o.id === id);
      return {
        disabled: isElementPresent
      }
    },
    openBar() {
      this.right_bar = !this.right_bar
    },
    // getMethodologies() {
    //   this.methodologiesGet()
    //       .then(res => {
    //         this.allMethodologies = res.data
    //       })
    // },
    methodModal(method) {
      this.selectedMethod = null
      if (method) {
        this.selectedMethod = method
      } else {
        this.selectedMethod = null
      }
      this.$refs['method-modal'].toggle('#method-btn')
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  &_container {
    display: flex;
    flex-direction: column;
  }
  &_head {
    width: 100%;
    height: 73px;
    background: linear-gradient(180deg, #003761 0%, #00528C 79.64%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    position: sticky;
    top: 0;
    z-index: 3;
    &-title {
      color: #FFFFFF;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  &_content {
    display: flex;
    position: relative;
    width: 100%;
    justify-content: space-between;

    &-container {
      max-width: 840px;
      transition: 0.6s;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 40px 60px;
      &.full_page {
        transition: 0.6s;
        margin-right: 100px;
      }
    }
    & .model {
      &_container {
        width: 100%;
        padding: 10px 0;
        display: flex;
        flex-wrap: wrap;
        //flex-direction: column;
      }
      &_item {
        width: 800px;
        background: #F3FAF8;
        border-radius: 14px;
        border: 1px solid #11334D;
        //filter: drop-shadow(0px 4px 12px #004475);
        padding: 15px 20px;
        margin: 15px 20px;
        &-text {
          color: #11334D;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          padding: 5px 0;
          &.description {
            color: #00A99D;
            font-size: 22px;
            font-weight: 600;
            padding-bottom: 5px;
            & .model_item-value {
              padding: 8px;
              color: #11334D;
              font-size: 20px;
              font-weight: 700;
              border: none;
              background: #F3FAF8;
            }
          }
        }
        &-key {
          color: #11334D;
          font-size: 18px;
          font-style: italic;
          font-weight: 500;
          line-height: normal;
        }
        &-value {
          width: 100%;
          display: flex;
          font-size: 16px;
          font-weight: 500;
          line-height: normal;
          max-height: 88px;
          overflow-y: auto;
          flex-wrap: wrap;
          position: relative;
          border-radius: 8px;
          border: 1px solid #11334D;
          min-height: 44px !important;
          background: #ffffff;
          align-items: center;
          padding: 8px 14px;
          &::-webkit-scrollbar {
            width: 10px;
            cursor: pointer;
          }
          &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
            border-radius: 18px;
            cursor: pointer;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 18px;
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
            cursor: pointer;
          }
          &_icon {
            position: absolute;
            top: 2px;
            left: -30px;
            width: 20px;
            height: 15px;
            background: url("~@/assets/icons/arrow_right_white.svg") no-repeat center;
            margin-right: 8px;
          }
        }

      }
    }
    &-title {
      color: #11334D;
      font-size: 22.677px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding: 20px 0;
    }
  }
  &_right-bar {
    position: fixed;
    right: 0;
    width: 780px;
    height: 100%;
    background: #00A99D;
    filter: drop-shadow(0px 5px 25px #004475);
    display: flex;
    align-items: center;
    transition: 0.6s;
    @media screen and (max-width: 1680px) {
      width: 700px;
    }

    & .bar_btn {
      cursor: pointer;
      margin-left: 14px;
    }

    &.open_bar {
      transition: 0.6s;
      width: 100px;
    }

    & .didactic {
      &_content {
        padding: 50px 40px 20px 22px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;

        & .models_container {
          padding: 30px;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;

          & .model_item {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 290px;
            height: 70px;
            border-radius: 8px;
            background: #ffffff;
            color: #11334D;
            font-size: 17px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            padding: 10px 20px;
            margin: 10px 0;
            position: relative;
            text-align: center;
            @media screen and (max-width: 1680px) {
              width: 250px;
            }

            & .add_layer {
              position: absolute;
              top: -30px;
              width: 100%;
              height: 100%;
              border-radius: 8px;
              backdrop-filter: blur(10px);
              transition: .3s;
              visibility: hidden;
              opacity: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              & .add_btn, & .show_btn {
                margin: 0 5px;
                padding: 4px 10px;
                border-radius: 12px;
                border: 1px solid #FFFFFF;
                background: #00A99D;
                color: #FFFFFF;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                &.disabled {
                  background: #9d9d9d;
                  pointer-events: none;
                }
              }
            }

            &:hover {
              & .add_layer {
                top: 0;
                transition: .3s;
                visibility: visible;
                opacity: 1;
              }
            }

          }
        }

        &.hide_content {
          display: none;
        }

        & .search {
          &_input {
            &-container {
              width: 100%;
              position: relative;

              & input {
                height: 54px;
                border-radius: 8px;
                border: 2px solid #11334D;
                padding: 0 70px 0 20px;

                &:focus {
                  outline: none;
                  box-shadow: none;
                }
              }
            }
          }

          &_icon {
            position: absolute;
            right: 20px;
            top: 10px;
            width: 36px;
            height: 36px;
            background: url("~@/assets/icons/green_loop.svg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;

          }
        }

        & .educative {
          &_title {
            text-align: center;
            color: #FFF;
            font-size: 28px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            padding: 50px 0 10px;
          }
        }
      }

      &_title {
        color: #FFF;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        padding-bottom: 20px;
      }

      &_search {
        /*padding: ;*/
        padding: 20px 0;
        display: flex;
        width: 100%;
        color: #11334D;
        font-size: 20px;
        font-style: italic;
        font-weight: 500;
        line-height: normal;

        & .search {
          &_radios {
            display: flex;

            & div {
              display: flex;
            }

            ::v-deep .custom-radio {
              padding: 0 50px !important;
              display: flex;
              align-items: center;

              & label {
                margin-left: 10px;
                color: #11334D;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
              }

              input[type="radio" i] {
                width: 20px;
                height: 20px;
              }
            }
          }

        }
      }

    }
  }
}
.modal {
  &_content {
    padding: 10px 20px;
  }

  &_title {
    color: #11334D;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 10px 0;
  }

  &_input {
    margin: 10px 0;
    & .method_item {
      border-radius: 8px;
      border: 1px solid #11334D;
      min-height: 44px !important;
      margin-bottom: 5px;
      padding: 8px 14px;
      display: flex;
      align-items: center;
      color: #11334D;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      max-height: 80px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 10px;
        cursor: pointer;
      }
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 18px;
        cursor: pointer;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 18px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        cursor: pointer;
      }
    }

    & label {
      color: #11334D;
      font-size: 18px;
      font-style: italic;
      font-weight: 500;
      line-height: normal;
    }

    & input {
      border: 1px solid #11334D;
      &::placeholder {
        color: #11334D;
        font-size: 18px;
        font-style: italic;
        font-weight: 500;
        line-height: normal;
      }
    }
  }

  &_btn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px 0 10px;

    & .cancel_btn,
    & .add_btn {
      height: 40px;
      border-radius: 12px;
      border: 1px solid #FFFFFF;
      background: #00A99D;
      color: #FFFFFF;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px 20px;
      cursor: pointer;
    }
  }
}
</style>